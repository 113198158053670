import React from 'react'
import './index.scss'

export default class tab4 extends React.Component {
  render() {
    return (
      <div id='tab4' className='tab4'>
        <div className='hit'>© 2005-2022 黑龙江晟唐小贷 版权所有，并保留所有权利。 黑龙江省哈尔滨市道里区富力江湾新城公建区T2栋29层2912号</div>
        <div className='hit' onClick={()=>{
          window.location.href = 'http://beian.miit.gov.cn/'
        }}>ICP备案证书号:黑ICP备20002707号-7</div>
      </div>
    )
  }
}